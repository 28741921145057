import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RightNav from "./RightNav";
import { useNavigate } from "react-router-dom";

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 20;
  display: none;
  user-select: none;

  @media (max-width: 1280px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  @media (max-width: 640px) {
    margin-top: 24px;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? "#0d2538" : "#333")};
    border-radius: 0.5rem;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ isGoBackPage }) => {
  const [open, setOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const navigate = useNavigate();

  const handleResize = () => {
    if (window.innerWidth <= 1280) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = () => {
    if (isMobileView) {
      setOpen(!open);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    setOpen(false);
  };

  return (
    <>
      <StyledBurger open={open} onClick={handleToggle}>
        <div />
        <div />
        <div />
      </StyledBurger>
      {isMobileView && (
        <RightNav
          open={open}
          handleNavigation={handleNavigation}
          isGoBackPage={isGoBackPage}
        />
      )}
      {!isMobileView && (
        <RightNav
          open={true}
          handleNavigation={handleNavigation}
          isGoBackPage={isGoBackPage}
        />
      )}
    </>
  );
};

export default Burger;

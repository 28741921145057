import React from "react";
import styled from "styled-components";
import Burger from "./Burger";
import logo from "../../assets/wineup-logo-color-large.png";
import { useNavigate } from "react-router-dom";

const Nav = styled.nav`
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 15%;
  align-items: center;

  @media (max-width: 1280px) {
    width: auto;
  }
`;

const Logo = styled.img`
  height: 5rem;
  width: 10rem;
  object-fit: contain;
`;

const Navbar = ({ isGoBackPage }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/")
  }
  return (
    <Nav>
      <TitleContainer onClick={handleClick}>
        <Logo src={logo} alt="logo" />
      </TitleContainer>
      <Burger isGoBackPage={isGoBackPage} />
    </Nav>
  );
};

export default Navbar;

import styled from "styled-components";
import Navbar from "../components/Nav/Navbar";
import wineAdventureHero from "../assets/wine-adventure-hero.png";
import { useMyLanguageContext } from "../LanguageContext";
import wineAdventure from "../assets/wine-adventure.png";
import DownloadApp from "../components/DownloadApp";
import Footer from "../components/Footer";

const AdventureContainer = styled.div`
  display: flex;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 8rem;
  margin-bottom: 8rem;
  align-items: center;

  @media (max-width: 640px) {
    margin-bottom: 4rem;
    margin-top: 4rem;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (min-width: 641px) and (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 6rem;
    margin-top: 6rem;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 4rem;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`;

const Container = styled.div`
  max-width: 1440px;
  margin:  0 auto;
  padding: 1rem 1rem;
  box-sizing: border-box;

  @media (max-width: 640px) {
    margin: 2rem 1rem;
    padding: 0 0.5rem 1.5rem;
    transition: width 0.5s ease;
  }
`;

const TitleContainer = styled.div`
  align-items: center;
  margin-bottom: 2rem;
`;

const Title = styled.span`
  font-family: Playfair Display, serif;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  color: #181725;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-right: 1rem;
  }
`;

const Text = styled.span`
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  color: #181725;
  margin-bottom: 1rem;
  letter-spacing: 0.05rem;
  ${({ fontWeight = 400 }) => `font-weight: ${fontWeight};`}
  ${({ fontStyle = "normal" }) => `font-style: ${fontStyle};`}
`;

const SubTitle = styled.span`
  font-family: Playfair Display, serif;
  font-size: 1.25rem;
  letter-spacing: 0.063rem;
  color: #181725;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 108.1%;
  letter-spacing: 0.8px;
  border-radius: 14px;
  background: #ff5a5f;
  border: solid 1px #ff5a5f;
  display: flex;
  padding: 16px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const WineAdventureCircleImage = styled.img`
  flex: 1;
  height: auto;
  max-height: 36.25rem;
  max-width: 36.25rem;
  margin: auto;
  transition: ease all 0.5s;

  @media (max-width: 768px) {
    width: 75%;
  }
`;

const BigContainer = styled.div`
  max-width: 1440px;
  margin: 4rem auto;
  padding: 0 1rem 1.5rem;
  box-sizing: border-box;

  @media (max-width: 640px) {
    margin: 2rem 1rem;
    padding: 0 0.5rem 1.5rem;
    transition: width 0.5s ease;
  }
`;

const WineAdventureImage = styled.img`
  width: 100%;
`;

const WineAdventure = () => {
  const { i18n, selectedLanguage } = useMyLanguageContext();

  return (
    <>
      <Container>
        <Navbar />
      </Container>
      <WineAdventureImage src={wineAdventureHero} alt="wine-adventure-hero" />
      <BigContainer>
        <AdventureContainer>
          <ContentContainer id="wine-adventure">
            <TitleContainer>
              <Title>{i18n.t("wineAdventureSection.title")}</Title>
            </TitleContainer>
            <Text fontWeight={700}>
              {i18n.t("wineAdventureSection.firstParagraph")}
            </Text>
            <Text>{i18n.t("wineAdventureSection.secondParagraph")}</Text>
            <SubTitle>{i18n.t("wineAdventureSection.thirdParagraph")}</SubTitle>
            <Text>{i18n.t("wineAdventureSection.fourthParagraph")}</Text>
            <Text>{i18n.t("wineAdventureSection.fifthParagraph")}</Text>
            <Text>
              {i18n.t("wineAdventureSection.sitxthParagraphFirstHalf")}
              <Text fontWeight={700}>{` ${i18n.t("wineAdventure")}®`}</Text>
              {i18n.t("wineAdventureSection.sitxthParagraphSecondHalf")}
            </Text>
            <Text fontWeight={700}>
              {i18n.t("wineAdventureSection.seventhParagraph")}
            </Text>
            <Text>
              {i18n.t("wineAdventureSection.eigthParagraphFirstHalf")}
              <Text fontWeight={700}>{` ${i18n.t("wineAdventure")}®${
                selectedLanguage === "hu" ? " " : ""
              }`}</Text>
              {i18n.t("wineAdventureSection.eigthParagraphSecondHalf")}
            </Text>
            <Text>{i18n.t("wineAdventureSection.ninthParagraph")}</Text>
            <Text>
              {i18n.t("wineAdventureSection.tenthParagraph")}
              <Text fontWeight={700} fontStyle="italic">
                {i18n.t("wineAdventureSection.eleventhParagraph")}
              </Text>
            </Text>
            <a
              href="https://www.boreskultura.hu/borkalandelmenydoboz"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Button style={{ textDecoration: "none" }}>
                {i18n.t("wineAdventureSection.seeDetails")}
              </Button>
            </a>
          </ContentContainer>
          <WineAdventureCircleImage src={wineAdventure} alt="wine-adventure" />
        </AdventureContainer>
        <DownloadApp />
      </BigContainer>
      <Footer />
    </>
  );
};

export default WineAdventure;

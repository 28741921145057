import React from "react";
import styled from "styled-components";
import backgroundImage from "../assets/Borfeszt.png";
import { ReactComponent as CalendarCheck } from "../assets/icons/CalendarCheck.svg";
import { useMyLanguageContext } from "../LanguageContext";

const ContainerFluid = styled.div`
  background: rgba(218, 218, 218, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  height: auto;

  @media (max-width: 640px) {
    padding: 2rem 0;
  }

  @media (max-width: 1110px) {
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  max-width: 1440px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
`;

const Title = styled.span`
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 500px;
`;

const TextContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Image = styled.div`
  height: 150px;
  align-self: stretch;
  border-radius: 40px 40px 0 0;
  background: url(${backgroundImage}) lightgray 50% / cover no-repeat;
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: #181725;
  font-family: Nunito,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 108.1%; 
  letter-spacing: 0.8px;
`;

const SubTitle = styled.h2`
  color: #181725;
  font-family: "Playfair Display", serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 108.1%;
  letter-spacing: 1.2px;
`;

const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
`;

const Button = styled.button`
  color: #FFF;
  font-family: Nunito,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 108.1%; 
  letter-spacing: 0.8px;
  border-radius: 14px;
  background: #FF5A5F;
  border: solid 1px #FF5A5F;
  display: flex;
  padding: 16px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #E14A50;
  }
  
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const StyledIcon = styled(CalendarCheck)`
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
`;

const Events = () => {
  const { i18n } = useMyLanguageContext();

  return (
    <ContainerFluid id={"events"}>
      <ContentContainer>
        <Title>
          {i18n.t("events.title")}
        </Title>
        <Card>
          <Image />
          <TextContainer>
            <Date>
              <StyledIcon />
              {i18n.t("events.date")}
            </Date>
            <SubTitle>{i18n.t("events.subtitle")}</SubTitle>
            <Description>{i18n.t("events.description")}</Description>
            <a
              href="https://szegediborfesztival.hu/csutortokon-nyitonap/"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button> {i18n.t("wineAdventureSection.seeDetails")}</Button>
            </a>
          </TextContainer>
        </Card>
      </ContentContainer>
    </ContainerFluid>
  );
};

export default Events;

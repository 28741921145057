import styled from "styled-components";
import { useMyLanguageContext } from "../LanguageContext";
import QuestionLine from "./QuestionLine";

const Container = styled.div`
  display: block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 8rem;
  margin-bottom: 8rem;

  @media (max-width: 640px) {
    margin-bottom: 4rem;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (min-width: 641px) and (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 6rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 4rem;
`;

const Title = styled.span`
  font-family: Playfair Display, serif;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  color: #181725;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-right: 1rem;
  }
`;

const Button = styled.button`
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 108.1%;
  letter-spacing: 0.8px;
  border-radius: 14px;
  background: #ff5a5f;
  border: solid 1px #ff5a5f;
  display: flex;
  padding: 16px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #E14A50;
  }

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const OutLinedButton = styled.button`
  color: #ff5a5f;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 108.1%;
  letter-spacing: 0.8px;
  border-radius: 14px;
  background: #fff;
  border: solid 1px #ff5a5f;
  display: flex;
  padding: 16px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
  margin-top: 4rem;
  ${({ isHome }) =>
    isHome &&
    `
  margin-bottom: 4rem;
`}
`;

const Questions = ({ isHome }) => {
  const { i18n } = useMyLanguageContext();

  const questions = [
    {
      title: i18n.t("qa.reg.first.question"),
      description: i18n.t("qa.reg.first.answer"),
    },
    {
      title: i18n.t("qa.reg.second.question"),
      description: i18n.t("qa.reg.second.answer"),
    },
    {
      title: i18n.t("qa.reg.third.question"),
      description: i18n.t("qa.reg.third.answer"),
    },
    {
      title: i18n.t("qa.adventure.first.question"),
      description: i18n.t("qa.adventure.first.answer"),
      last: isHome,
    },
    {
      title: i18n.t("qa.adventure.second.question"),
      description: i18n.t("qa.adventure.second.answer"),
    },
    {
      title: i18n.t("qa.adventure.third.question"),
      description: i18n.t("qa.adventure.third.answer"),
    },
    {
      title: i18n.t("qa.events-and-challenge.first.question"),
      description: i18n.t("qa.events-and-challenge.first.answer"),
    },
    {
      title: i18n.t("qa.ratings.first.question"),
      description: i18n.t("qa.ratings.first.answer"),
    },
    {
      title: i18n.t("qa.account-delete.first.question"),
      description: i18n.t("qa.account-delete.first.answer"),
      last: true,
    },
  ];

  const displayedQuestions = isHome ? questions.slice(0, 4) : questions;

  return (
    <Container>
      <TitleContainer>
        <Title>{i18n.t("qa.title")}</Title>
      </TitleContainer>
      {displayedQuestions.map((question, index) => (
        <QuestionLine
          key={index}
          title={question.title}
          description={question.description}
          last={question?.last}
        />
      ))}
      <ButtonContainer isHome={isHome}>
        {isHome ? (
          <a href="/questions-and-answers" style={{ textDecoration: "none" }}>
            <OutLinedButton style={{ textDecoration: "none" }}>
              {i18n.t("qa.moreButton")}
            </OutLinedButton>
          </a>
        ) : null}
        <a href="mailto:info@mywineup.com" style={{ textDecoration: "none" }}>
          <Button style={{ textDecoration: "none" }}>
            {i18n.t("featuresforWineries.contact")}
          </Button>
        </a>
      </ButtonContainer>
    </Container>
  );
};

export default Questions;

import React from "react";
import styled from "styled-components";
import borKulturaPartner from "../assets/bor-kultura.jpg";
import logo from "../assets/logo.png";
import { useMyLanguageContext } from "../LanguageContext";

const Container = styled.div`
  display: block;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 8rem;
  margin-bottom: 8rem;

  @media (max-width: 640px) {
    margin-bottom: 4rem;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (min-width: 641px) and (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 6rem;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`;

const Title = styled.span`
  font-family: Playfair Display, serif;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  color: #181725;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
`;

const PartnersLink = styled.a`
  text-decoration: none;
`;

const PartnersLogo = styled.img`
  margin-right: auto;
  margin-left: auto;
`;

const Logo = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  object-fit: contain;
`;

const Partners = () => {
  const { i18n } = useMyLanguageContext();

  return (
    <Container id="partners">
      <TitleContainer>
        <Logo src={logo} alt="logo" />
        <Title>{i18n.t("ourPartners")}</Title>
      </TitleContainer>
      <ContentContainer>
        <PartnersLink
          href="https://www.boreskultura.hu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <PartnersLogo src={borKulturaPartner} alt="partners" />
        </PartnersLink>
      </ContentContainer>
    </Container>
  );
};

export default Partners;

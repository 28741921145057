import React from "react";
import styled from "styled-components";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { useMyLanguageContext } from "../LanguageContext";

const Container = styled.div`
  display: flex;
  padding: 0 1.5rem;
  place-content: space-evenly;
  gap: 2.5rem;
  flex-wrap: wrap;
  padding: 4rem 0;
  background-color: #181725;

  @media (max-width: 640px) {
    place-content: flex-start;
    padding-left: 1.5rem;
  }

  @media (min-width: 641px) and (max-width: 768px) {
    place-content: flex-start;
    padding-left: 1.5rem;
  }
`;

const ColumnContainer = styled.div`
  display: block;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 75%;
  align-items: center;
  margin-bottom: 1rem;
`;

const Logo = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  object-fit: contain;
`;

const Title = styled.span`
  font-family: Playfair Display, serif;
  font-size: 1.5rem;
  letter-spacing: 0.075rem;
  color: #FFF;
`;

const TextContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const Text = styled.span`
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  color: #FFF;
  margin-bottom: 0.5rem;
  letter-spacing: 0.05rem;
  ${({ fontWeight = 400 }) => `font-weight: ${fontWeight};`}
  ${({ fontStyle = "normal" }) => `font-style: ${fontStyle};`}
`;

const Footer = () => {
  const { i18n } = useMyLanguageContext();

  return (
    <Container id="contact">
      <ColumnContainer>
        <TitleContainer>
          <Logo src={logo} alt="logo" />
          <Title>WineUp!</Title>
        </TitleContainer>
        <Text>{i18n.t("slogan")}</Text>
      </ColumnContainer>
      <ColumnContainer>
        <TitleContainer>
          <Title>{i18n.t("links")}</Title>
        </TitleContainer>
        <TextContainer>
          <Text>
            <Link style={{color: '#FFF'}} to="/terms-and-conditions">
              {i18n.t("termsAndConditions")}
            </Link>
          </Text>
          <Text>
            <Link style={{color: '#FFF'}} to="/privacy-policy">{i18n.t("privacyPolicy")}</Link>
          </Text>
          <Text>
            <a
              href="https://app.mywineup.com/delete-profile"
              target={"_blank"}
              rel="noreferrer"
              style={{color: '#FFF'}}
            >
              {i18n.t("deleteAccount")}
            </a>
          </Text>
        </TextContainer>
      </ColumnContainer>
      <ColumnContainer>
        <TitleContainer>
          <Title>{i18n.t("contactUs")}</Title>
        </TitleContainer>
        <TextContainer>
          <Text>
            <a style={{color: '#FFF'}} href="mailto:info@mywineup.com">info@mywineup.com</a>
          </Text>
        </TextContainer>
      </ColumnContainer>
    </Container>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Navbar from "../components/Nav/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useMyLanguageContext } from "../LanguageContext";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  max-width: 1440px;
  margin: 0;
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 640px) {
    margin: 2rem 1rem;
    padding: 0 0.5rem 1.5rem;
    transition: width 0.5s ease;
  }

  @media (min-width: 1281px) {
    margin: 0 auto;
  }
`;

const BigContainer = styled.div`
  flex: 1;
  max-width: 1440px;
  margin: auto;
  padding: 0 1rem 1.5rem;
  box-sizing: border-box;
  width: 60%;
  text-align: center;

  @media (max-width: 640px) {
    margin: 2rem 1rem;
    padding: 0 0.5rem 1.5rem;
    transition: width 0.5s ease;
    width: auto;
    text-align: start;
  }
`;

const Title = styled.h1`
  font-family: Playfair Display, serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 108.1%;
  letter-spacing: 0.1125rem;
  margin-top: 0;
  color: ${({ color }) => color ?? "#181725"};
  ${({ marginbottom = 0 }) => `margin-bottom: ${marginbottom}rem;`}
`;

const Description = styled.span`
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  color: #181725;
`;

const Unsubscribe = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const navigate = useNavigate();
  const { i18n } = useMyLanguageContext();
  const [errorMessage, setErrorMessage] = useState(false);

  const unsubscribe = () => {
    axios
      .post(searchParams.get("unsubscribeUrl"))
      .catch((e) => setErrorMessage(e.message));
  };

  useEffect(() => {
    if (searchParams.get("unsubscribeUrl")) {
      unsubscribe();
    } else {
      navigate("/");
    }
  });
  return (
    <PageContainer>
      <ContentContainer>
        <Navbar />
      </ContentContainer>
      <BigContainer>
          <Title color='#FF5A5F' marginbottom={1}>{i18n.t("unsubscribe.title")}</Title>
          <Description>
            {errorMessage ? i18n.t("unsubscribe.error") : i18n.t("unsubscribe.text")}
          </Description>
      </BigContainer>
      <Footer />
    </PageContainer>
  );
};

export default Unsubscribe;

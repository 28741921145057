import React from "react";
import styled from "styled-components";
import backgroundImage from "../assets/Discover-new-opportunities.png";
import { useMyLanguageContext } from "../LanguageContext";

const ContainerFluid = styled.div`
  background: url(${backgroundImage}) no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  height: auto;

  @media (max-width: 640px) {
    padding: 2rem 0;
  }

  @media (max-width: 1110px) {
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  text-align: center;
`;

const Title = styled.span`
  font-family: Playfair Display, serif;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  text-align: center;
`;

const NewOpportunities = () => {
  const { i18n } = useMyLanguageContext();

  return (
    <ContainerFluid>
      <ContentContainer>
        <Title marginBottom={1}> 
          {i18n.t("opportunitiesSection.opportunitiesTitle")}
        </Title>
      </ContentContainer>
    </ContainerFluid>
  );
};

export default NewOpportunities;
import React from "react";
import styled from "styled-components";
import { ReactComponent as Star } from "../assets/icons/Star.svg";
import { ReactComponent as CalendarCheck } from "../assets/icons/CalendarCheck.svg";
import { ReactComponent as DownloadSimple } from "../assets/icons/DownloadSimple.svg";
import { ReactComponent as Trophy } from "../assets/icons/Trophy.svg";
import { ReactComponent as MagnifyingGlass } from "../assets/icons/MagnifyingGlass.svg";
import { useMyLanguageContext } from "../LanguageContext";

const Container = styled.div`
  display: block;
  padding-left: 4rem;
  padding-right: 4rem;
  align-self: stretch;
  margin-bottom: 8rem;

  @media (max-width: 575.98px) {
    margin-bottom: 4rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (min-width: 576px) and (max-width: 991.98px) {
    margin-bottom: 6rem;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 575.98px) {
    flex-direction: column;
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 991.98px) {
    flex-direction: row;
    justify-content: center;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    flex-direction: row;
    justify-content: center;
    align-self: stretch;
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-around;
    align-self: stretch;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  min-width: 13.625rem;
  padding: 0.5rem;

  @media (max-width: 575.98px) {
    width: 100%;
  }

  @media (min-width: 576px) and (max-width: 991.98px) {
    width: calc(50% - 1rem);
  }

  @media (min-width: 992px) {
    width: calc(33.33% - 1rem);
    max-width: 218px;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 4rem;
`;

const Description = styled.span`
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  color: #181725;
`;
const SectionTitle = styled.span`
  font-family: Playfair Display, serif;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  color: #181725;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
`;

const Title = styled.span`
  font-family: Playfair Display, serif;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  color: #181725;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const StyledIcon = styled.div`
  margin-bottom: 1rem;
  border-radius: 3.375rem; 
  background: #FFF;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.10);
  display: flex;
  width: 2rem; 
  height: 2rem; 
  justify-content: center;
  align-items: center;
  padding: 2rem; 
  svg {
    height: 100%;
    width: 100%;
  }
`;

const Section = ({  icon: Icon, title, description }) => (
  <ContentContainer>
    <StyledIcon>
      <Icon />
    </StyledIcon>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </ContentContainer>
);

const HowItWorks = () => {
  const { i18n } = useMyLanguageContext();

  const sections = [
    {
      icon: DownloadSimple,
      title: i18n.t("howItWorksSection.howItWorksRegistrationTitle"),
      description: i18n.t("howItWorksSection.howItWorksRegistrationDescription"),
    },
    {
      icon: MagnifyingGlass,
      title: i18n.t("howItWorksSection.howItWorksDiscoverTitle"),
      description: i18n.t("howItWorksSection.howItWorksDiscoverDescription"),
    },
    {
      icon: Star,
      title: i18n.t("howItWorksSection.howItWorksShareItTitle"),
      description: i18n.t("howItWorksSection.howItWorksShareItDescription"),
    },
    {
      icon: CalendarCheck,
      title: i18n.t("howItWorksSection.howItWorksJoinTitle"),
      description: i18n.t("howItWorksSection.howItWorksJoinDescription"),
    },
    {
      icon: Trophy,
      title: i18n.t("howItWorksSection.howItWorksGetCouponsTitle"),
      description: i18n.t("howItWorksSection.howItWorksGetCouponsDescription"),
    },
  ];

  return (
     <Container id="howItWorks">
      <TitleContainer>
        <SectionTitle>{i18n.t("howItWorks")}</SectionTitle>
      </TitleContainer>
      <RowContainer>
        {sections.map((section, index) => (
          <Section
            key={index}
            icon={section.icon}
            title={section.title}
            description={section.description}
          />
        ))}
      </RowContainer>
    </Container>
  );
};

export default HowItWorks;

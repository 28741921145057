import React from "react";
import styled from "styled-components";
import cta_bg_mobile from "../assets/cta-bg-mobil.png"; 
import googleDownload from "../assets/google-download.png";
import appleDownload from "../assets/apple-download.png";
import mobilHome from "../assets/mobil.png"; 
import { APPSTORE_LINK, GOOGLEPLAY_LINK } from "../utils/consts";
import { useMyLanguageContext } from "../LanguageContext";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  background-image: url(${cta_bg_mobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 400px;
  padding: 0 2rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 2.5rem;
  margin-bottom: 8rem;
  padding: 2rem;
  display: flex; 

  @media (max-width: 768px) {
    flex-direction: column; 
    align-items: center;
    justify-content: center; 
    padding-top: 2rem; 
  }
`;

const MobilHomeImage = styled.img`
  position: relative;
  right: 5%;
  bottom: 0;
  top: 0;
  max-height: 445px;

  @media (max-width: 768px) {
    position: static;
    max-width: 100%;
    margin: 0 auto;
    transform: none;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2rem;
  text-align: left;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const Title = styled.span`
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  color: white;
  font-weight: bold;
  max-width: 50%;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    max-width: 100%;
  }

  @media (max-width: 410px) {
    font-size: 1.5rem;
  }
`;

const Text = styled.span`
  font-family: "Nunito", sans-serif;
  font-size: 1.25rem;
  color: white;
  margin-bottom: 2rem;
  ${({ fontWeight = 500 }) => `font-weight: ${fontWeight};`}


`;

const DownloadButtonImage = styled.img`
  ${({ marginright = 0 }) => `margin-right: ${marginright}px;`}
  cursor: pointer;

  @media (max-width: 410px) {
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  @media (max-width: 410px) {
    flex-direction: column;
  }
`;

const DownloadApp = () => {
  const { i18n } = useMyLanguageContext();

  return (
    <Container>
      <ContentContainer id="download-app">
        <TitleContainer>
          <Title>{i18n.t("wineUpPromotion.title")}</Title>
          <Text fontWeight={700}>{i18n.t("wineUpPromotion.secondaryTitle")}</Text>
          <ButtonContainer id="store-image">
            <a href={GOOGLEPLAY_LINK} target="_blank" rel="noreferrer">
              <DownloadButtonImage
                src={googleDownload}
                alt="Get it on Google Play"
                marginright={8}
              />
            </a>
            <a href={APPSTORE_LINK} target="_blank" rel="noreferrer">
              <DownloadButtonImage
                src={appleDownload}
                alt="Download on the App Store"
              />
            </a>
          </ButtonContainer>
        </TitleContainer>
      </ContentContainer>
      <MobilHomeImage src={mobilHome} alt="Mobile Home Image" />
    </Container>
  );
};

export default DownloadApp;

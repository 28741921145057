import React from "react";
import styled from "styled-components";
import { useMyLanguageContext } from "../../LanguageContext";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  user-select: none;
  gap: 3rem;

  li {
    font-family: Nunito, sans-serif;
    font-size: 1rem;
    color: #181725;
    cursor: pointer;
    padding: 1rem 0.5rem;
    ${({ fontStyle = "normal" }) => `font-style: ${fontStyle};`}

    &:hover {
      color: #ff5a5f;
    }
  }

  @media (max-width: 1280px) {
    flex-flow: column nowrap;
    background-color: #fff;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: -1rem;
    right: 0;
    height: 100vh;
    width: 18.75rem;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 2;
    box-shadow: ${({ open }) => (open ? "rgba(17, 0, 53, 0.25) 0px 64px 50px" : "none")};
    gap: 1rem;
    li {
      color: #0d2538;
    }
  }

  @media (min-width: 1281px) {
    align-items: center;
  }
`;

const StyledSelect = styled.select`
  border: 1px solid #FF5A5F;
  background-color: #FFF;
  border-radius: 0.5rem;
  padding: 0.5rem;
`;

const RightNav = ({ open, handleNavigation, isGoBackPage }) => {
  const { i18n, changeLanguage, selectedLanguage } = useMyLanguageContext();

  return (
    <Ul open={open}>
      {isGoBackPage ? (
        <li onClick={() => handleNavigation()}> {i18n.t("back")}</li>
      ) : (
        <>
          <li onClick={() => handleNavigation("/#howItWorks")}>
            <a href="/#howItWorks" style={{textDecoration: 'none', color: '#181725'}}>{i18n.t("howItWorks")}</a>
          </li>
          <li onClick={() => handleNavigation("/#feature-for-winery")}>
            <a href="/#feature-for-winery" style={{textDecoration: 'none', color: '#181725'}}>{i18n.t("featuresforWineries.winery")}</a>
          </li>
          <li onClick={() => handleNavigation("/#feature-for-wine-consumer")}>
            <a href="/#feature-for-wine-consumer" style={{textDecoration: 'none', color: '#181725'}}>{i18n.t("featuresforWineConsumers.winery")}</a>
          </li>
          <li onClick={() => handleNavigation("/#events")}>
            <a href="/#events" style={{textDecoration: 'none', color: '#181725'}}>{i18n.t("eventsTitle")}</a>
          </li>
          <li onClick={() => handleNavigation("/wine-adventure")}>
            <a href="/wine-adventure" style={{textDecoration: 'none', color: '#181725'}}>{i18n.t("wineAdventure")}</a>
          </li>
          <li onClick={() => handleNavigation("/#partners")}>
            <a href="/#partners" style={{textDecoration: 'none', color: '#181725'}}>{i18n.t("ourPartners")}</a>
          </li>
          <li onClick={() => handleNavigation("/questions-and-answers")}>
            <a href="/questions-and-answers" style={{textDecoration: 'none', color: '#181725'}}>{i18n.t("qanda")}</a>
          </li>
          <li>
            <StyledSelect
              onChange={(e) => changeLanguage(e)}
              value={selectedLanguage}
            >
              <option value="hu">HU</option>
              <option value="en">EN</option>
            </StyledSelect>
          </li>
        </>
      )}
    </Ul>
  );
};

export default RightNav;

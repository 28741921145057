import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 1.5rem;
  width: 100%;

  @media (max-width: 640px) {
    transition: width 0.5s ease;
  }
`;

export const CreatedAndUpdatedTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Text = styled.span`
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  color: #181725;
  ${({ fontWeight = 400 }) => `font-weight: ${fontWeight};`}
  ${({ fontStyle = "normal" }) => `font-style: ${fontStyle};`}
  ${({ marginBottom = 0 }) => `margin-bottom: ${marginBottom}rem;`}
  ${({ opacity = 1 }) => `opacity: ${opacity};`}
`;

export const MainTitle = styled.h1`
  font-family: Nunito, sans-serif;
  font-size: 1.5rem;
  ${({ color = "#781847" }) => `color: ${color};`}
  ${({ alignSelf = "flex-start" }) => `align-self: ${alignSelf};`}
`;

export const WineUpInText = styled.span`
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  color: #781847;
`;

export const BlueItalicText = styled.span`
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  color: #228dda;
  font-style: italic;
`;

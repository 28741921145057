import React from "react";
import styled from "styled-components";
import features from "../assets/borbaratoknak-img.png";
import { ReactComponent as CalendarCheck } from "../assets/icons/CalendarCheck_dark.svg";
import { ReactComponent as Graph } from "../assets/icons/Graph.svg";
import { ReactComponent as Wine } from "../assets/icons/Wine.svg";
import { ReactComponent as Ticket } from "../assets/icons/Ticket.svg";
import logo from "../assets/logo.png";
import googleDownload from "../assets/google-download.png";
import appleDownload from "../assets/apple-download.png";
import { APPSTORE_LINK, GOOGLEPLAY_LINK } from "../utils/consts";
import { useMyLanguageContext } from "../LanguageContext";

const Container = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 8rem;
  align-items: center;
  gap: 4rem;
  margin: 8rem auto;


  @media (max-width: 640px) {
    margin-bottom: 4rem;
    flex-direction: column-reverse;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (min-width: 641px) and (max-width: 1024px) {
    flex-direction: column-reverse;
    flex-direction: column;
    margin-bottom: 6rem;
  }
`;

const ColumnContainer = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: center;  
  text-align: left;
  align-items: flex-start;
  gap: 2rem
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: left;
  text-align: left;
  flex-direction: row;
  margin-bottom: 0.5rem;
  align-items: center; 
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.span`
  font-family: Playfair Display, serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 108.1%;
  letter-spacing: 0.1125rem;
  color: ${({ color }) => color ?? "#181725"};
  ${({ marginbottom = 0 }) => `margin-bottom: ${marginbottom}rem;`}
`;

const SubTitle = styled.span`
  font-family: Playfair Display, serif;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  color: #181725;
  font-weight: bold;
  @media (max-width: 768px) {
    margin-right: 1rem;
  }
`;

const FeaturesImage = styled.img`
  height: 100%;
  max-width: 560px;
  width: 100%;
  transition: ease all 0.5s;
  margin: 0 auto; 

  @media (min-width: 641px) and (max-width: 768px) {
    width: 75%;
  }
`;

const Description = styled.span`
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  color: #181725;
`;

const StyledIcon = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

const WineryBadge = styled.button`
  color: #ff5a5f; 
  font-family: Nunito, sans-serif; 
  font-size: 1rem; 
  font-style: normal; 
  font-weight: 700; 
  line-height: 108.1%; 
  letter-spacing: 0.8px; 
  text-transform: uppercase; 
  border-radius: 3.375rem; 
  background: #f8f6f8; 
  display: flex; 
  align-items: center; 
  gap: 0.5rem;
  border: none; 
  cursor: pointer; 
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
`;

const Logo = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  object-fit: contain;
`;

const BadgeContainer = styled.div`
  display: block;
  flex-direction: column;
  align-items: left; 
`;
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  @media (max-width: 375px) {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    gap: 0.5rem;
  }
`;
const DownloadButtonImage = styled.img`
  ${({ marginright = 0 }) => `margin-right: ${marginright}px;`}
  cursor: pointer;

  @media (max-width: 375px) {
    margin-right: 0;
  }
`;


const Section = ({ icon: Icon, title, description }) => (
  <ContentContainer>
    <RowContainer>
      <StyledIcon>
        <Icon />
      </StyledIcon>
      <SubTitle>{title}</SubTitle>
    </RowContainer>
    <Description>{description}</Description>
  </ContentContainer>
);

const FeaturesforWineConsumers = () => {
  const { i18n } = useMyLanguageContext();

  const sections = [
    {
      icon: Graph,
      title: i18n.t("featuresforWineConsumers.recommendationsTitles"),
      description: i18n.t("featuresforWineConsumers.recommendationsContent"),
    },
    {
      icon: Wine,
      title: i18n.t("featuresforWineConsumers.discoverTitle"),
      description: i18n.t("featuresforWineConsumers.discoverContent"),
    },
    {
      icon: CalendarCheck,
      title: i18n.t("featuresforWineConsumers.eventManagementTitle"),
      description: i18n.t("featuresforWineConsumers.eventManagementContent"),
    },
    {
      icon: Ticket,
      title: i18n.t("featuresforWineConsumers.couponsTitle"),
      description: i18n.t("featuresforWineConsumers.couponsContent"),
    }
  ];

  return (
    <Container id="feature-for-wine-consumer">
       <ColumnContainer>
      <BadgeContainer>
          <WineryBadge>
            <Logo src={logo} alt="logo"></Logo>
            {i18n.t("featuresforWineConsumers.winery")}
          </WineryBadge>
          <Title marginbottom={0.5}>
            {i18n.t("featuresforWineConsumers.sectionTitle")}
          </Title>
        </BadgeContainer>
        {sections.map((section, index) => (
          <Section
            key={index}
            icon={section.icon}
            title={section.title}
            description={section.description}
          />
        ))}

        <ButtonContainer>
          <a href={GOOGLEPLAY_LINK} target="_blank" rel="noreferrer">
            <DownloadButtonImage
              src={googleDownload}
              alt="Get it on Google Play"
              marginright={8}
            />
          </a>
          <a href={APPSTORE_LINK} target="_blank" rel="noreferrer">
            <DownloadButtonImage
              src={appleDownload}
              alt="Download on the App Store"
            />
          </a>
        </ButtonContainer>
      </ColumnContainer>
      <FeaturesImage src={features} alt="features" />
    </Container>
  );
};

export default FeaturesforWineConsumers;

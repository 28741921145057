import { createContext, useContext, useEffect, useState } from "react";
import { I18n } from "i18n-js";
import hu from "./i18/hu.json";
import en from "./i18/en.json";

const MyLanguageContext = createContext();

const i18n = new I18n({
  ...hu,
  ...en,
});

i18n.defaultLocale = "hu";

const LanguageContextProvider = ({ children }) => {
  const [storedLanguage, setStoredLanguage] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("wineUpLanguage") ?? "hu";
    i18n.locale = storedLanguage;
    setStoredLanguage(storedLanguage);
    setSelectedLanguage(storedLanguage);
  }, []);

  const changeLanguage = (event) => {
    const newLanguageCode = event.target.value;
    i18n.locale = newLanguageCode;
    setSelectedLanguage(newLanguageCode);
    localStorage.setItem("wineUpLanguage", newLanguageCode);
  };

  return (
    <MyLanguageContext.Provider
      value={{ i18n, selectedLanguage, setSelectedLanguage, changeLanguage }}
    >
      {children}
    </MyLanguageContext.Provider>
  );
};

const useMyLanguageContext = () => {
  return useContext(MyLanguageContext);
};

export { LanguageContextProvider, useMyLanguageContext };

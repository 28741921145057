import React from "react";
import Navbar from "../components/Nav/Navbar";
import {
  Container,
  CreatedAndUpdatedTextContainer,
  Text,
  MainTitle,
  WineUpInText,
  BlueItalicText,
} from "../components/styled-components";

const TermsAndConditions = () => {
  return (
    <>
      <Navbar isGoBackPage />
      <Container>
        <CreatedAndUpdatedTextContainer>
          <Text marginBottom={1}>Létrehozva: 2023. október 25.</Text>
          <Text>Frissítve: 2023. november 7.</Text>
        </CreatedAndUpdatedTextContainer>
        <MainTitle alignSelf="center">WINEUP!</MainTitle>
        <MainTitle color="#181725" alignSelf="center">
          ÁLTALÁNOS SZERZŐDÉSI ÉS FELHASZNÁLÁSI FELTÉTELEK (ÁSZF)
        </MainTitle>
        <Text fontStyle="italic">
          Kérjük jelen ÁSZF-et figyelmesen olvassa el, mivel az ÁSZF rögzíti
          Mobilalkalmazásunk és Weboldalunk az Ön között létrejövő jogviszonyt,
          illetve szerződés rendelkezéseit, illetve hasznos és fontos adatokat
          tudhat meg a Mobilalkalmazás és Weboldal üzemeltetőjéről, a
          panaszkezelésről, és az Önt megillető egyéb jogokról és
          kötelezettségekről.
        </Text>
        <MainTitle>I. ÁLTALÁNOS RENDELKEZÉSEK</MainTitle>
        <Text marginBottom={1}>
          <Text fontWeight={700}>I. 1.</Text>
          Ezt a weboldalt és a <WineUpInText>WINEUP! </WineUpInText>
          mobilalkalmazást („Alkalmazás”) és a rajta szereplő információkat, a
          weboldalon és az alkalmazáson keresztül elérhető szolgáltatásokat és
          erőforrásokat, együttesen: a „Szolgáltatásokat” (minden weboldalt,
          alkalmazást és szolgáltatást, együttesen:
          <WineUpInText> „WINEUP!”</WineUpInText>) az IVE IT TEAM KFT.
          irányítja.
        </Text>
        <Text>Cég neve: IVE IT TEAM KORLÁTOLT FELELŐSSÉGŰ TÁRSASÁG</Text>
        <Text>Cég rövidített neve: IVE IT TEAM KFT.</Text>
        <Text>Cég képviselő neve: Jobbágy Róbert</Text>
        <Text>Cég székhelye: 6724 Szeged, Pulz utca 46/b. /112.</Text>
        <Text>Cég adószáma: 25818873-2-06.</Text>
        <Text>Cég EU adószáma: HU25818873</Text>
        <Text>Cég cégjegyzékszáma: 06 09 023192</Text>
        <Text>
          {`E-mail címe: `}
          <a href="mailto:robert.jobbagy@iveitteam.hu">
            robert.jobbagy@iveitteam.hu
          </a>
        </Text>
        <Text>
          Honlap(ok):{" "}
          <a href="https://mywineup.com" target="_blank" rel="noreferrer">
            https://mywineup.com
          </a>
        </Text>
        <Text marginBottom={1}>
          A továbbiakban: <Text fontWeight={700}>Szolgáltató</Text>
        </Text>
        <Text>
          Tárhely szolgáltató neve: Hetzner Online GmbH (a továbbiakban: Tárhely
          szolgáltató)
        </Text>
        <Text>
          Tárhely szolgáltató székhelye: Industriestr. 25 91710 Gunzenhausen,
          Deutschland
        </Text>
        <Text marginBottom={1}>
          Tárhely szolgáltató webes címe:{" "}
          <a href="https://www.hetzner.com" target="_blank" rel="noreferrer">
            https://www.hetzner.com
          </a>
        </Text>
        <Text marginBottom={1}>
          Telefon: <a href="tel:+49 9831 505 0">+49 9831 505 0</a>
        </Text>
        <Text marginBottom={1}>E-mail: support@hetzner.com</Text>
        <Text fontWeight={700}>I. 2. A Felhasználó</Text>
        <Text marginBottom={1}>
          Felhasználó az, aki a Szolgáltató honlapján a szolgáltatást igénybe
          veszi és az ÁSZF-et magára nézve kötelezőnek fogadja el. A Felhasználó
          a honlapon található információs adatlap kitöltésével adja meg
          adatait. A Szolgáltató ezeknek megfelelően felel az adatok
          kezeléséért. A Felhasználónak a bejelentkezés során lehetősége van
          adatait ellenőrizni/módosítani. A hibás vagy hamis adatok megadásából
          eredő károkért minden felelősség a Felhasználót terheli. A Szolgáltató
          a nyilvánvalóan hibás vagy hamis bejelentkezést jogosult törölni,
          továbbá kétség esetén a Felhasználó valódiságát jogosult ellenőrizni.
        </Text>
        <Text fontWeight={700}>I. 3. Borászat</Text>
        <Text marginBottom={1}>
          A <WineUpInText>WINEUP! </WineUpInText> szolgáltatáshoz csatlakozó
          borászatok, akik saját termékeiik és szolgáltatásaik bemutatásával
          népszerűsítik magukat a weboldalon és a mobilalkalmazáson keresztül. A
          borászatok a saját maguk által közzétett információk tekintetében
          tartoznak felelősséggel.
        </Text>
        <Text fontWeight={700}>I. 4. Alkalmazás és Szolgáltatás</Text>
        <Text>
          A <WineUpInText>WINEUP! </WineUpInText> a regisztrált Felhasználók
          részére a Szolgáltatáshoz csatlakozó borászatokat és azok termékeit
          mutatja be, a borászatok weboldalára navigál. A{" "}
          <WineUpInText>WINEUP! </WineUpInText> lehetővé teszi, hogy a
          Felhasználó regisztrált fiókjában tárolja egyedi ízlését reprezentáló
          borok listáját, azokat értékelheti. A Felhasználó egyedi ízlését
          tükröző, bizonyos számú értékeléseit elemezve a{" "}
          <WineUpInText>WINEUP! </WineUpInText> bor ajánlást készít és továbbít
          a Felhasználó fiókjába. A <WineUpInText>WINEUP! </WineUpInText>{" "}
          Szolgáltatáshoz csatlakozó borászatok meghatározott, borral
          kapcsolatos eseményeit promótálja a Felhasználók részére. A{" "}
          <WineUpInText>WINEUP! </WineUpInText> nem értékesít borokat
        </Text>
        <Text>
          A <WineUpInText>WINEUP! </WineUpInText> nem értékesít borokat vagy
          bármely más termékeket, a szolgáltatás a Felhasználó egyedi ízlésére
          szabott tartalmakat tarthat nyilván és ismerhet meg a weboldalon és az
          alkalmazáson keresztül.
        </Text>
        <MainTitle>II. A WINEUP! HASZNÁLATA</MainTitle>
        <Text marginBottom={1}>
          A jelen ÁSZF a <WineUpInText>WINEUP! </WineUpInText> használatára
          irányadók, és a <WineUpInText>WINEUP!</WineUpInText>-ot látogató
          minden Felhasználóra vonatkoznak.
        </Text>
        <Text fontWeight={700}>II.1. Regisztráció</Text>
        <Text>
          1. A <WineUpInText>WINEUP! </WineUpInText> használatához egy
          regisztrált Felhasználói fiók szükséges. A Felhasználói fiók
          létrehozásakor Ön köteles valós és pontos információkat megadni.
        </Text>
        <Text>
          2. A Felhasználói fiók adatainak biztonságáért Ön teljes mértékben
          felelős. Ne ossza meg fiókadatait másokkal, és gondoskodjon arról,
          hogy harmadik felek ne férhessenek hozzá a fiókjához.
        </Text>
        <Text marginBottom={1}>
          3. A hírlevélre való feliratkozás opcionális.
        </Text>
        <Text fontWeight={700}>II.2. Használati szabályok</Text>
        <Text>
          1. A <WineUpInText>WINEUP! </WineUpInText> elérésével vagy
          használatával, az „elfogadom” gombra kattintással, a regisztrációs
          folyamat befejezésével és/vagy a <WineUpInText>WINEUP! </WineUpInText>
          böngészésével vagy az alkalmazás letöltésével Ön kijelenti, hogy
        </Text>
        <Text>
          (a) elolvasta, megértette, egyetért és elfogadja a kötelező a
          felhasználási feltételeket (ÁSZF-et),
        </Text>
        <Text>
          (b) Ön elmúlt 18 éves (vagy a mindenkor hatályos jogszabályok szerinti
          felnőttkort betöltötte), ezáltal jogosult a{" "}
          <WineUpInText>WINEUP!</WineUpInText>–pal kapcsolatba lépni, és
        </Text>
        (c) a valós személyi adatai közlésére köteles, amellyel jogosult és
        köteles a felhasználási feltételeket (ÁSZF-et) betartani.
        <Text>
          2. Az „Ön” kifejezés utalhat magán- vagy jogi személyre, aki
          Felhasználóként azonosítja magát a{" "}
          <WineUpInText>WINEUP!</WineUpInText> regisztráció során.
        </Text>
        <Text>
          3. Ha nem fogadja el az általános szerződési és felhasználási
          feltételeket, nem férhet hozzá és nem használhatja a{" "}
          <WineUpInText>WINEUP!</WineUpInText>-ot.
        </Text>
        <Text>
          4. Felhívjuk a figyelmet, hogy az ÁSZF-et a{" "}
          <WineUpInText>WINEUP!</WineUpInText> saját belátása szerint bármikor
          módosíthatja. Ha módosításokat hajtottak végre, a{" "}
          <WineUpInText>WINEUP!</WineUpInText> az ÁSZF új példányát elérhetővé
          teszi a webhelyen és az alkalmazáson belül, és minden új kiegészítő
          feltétel elérhető lesz az érintett Szolgáltatáson belülről vagy azon
          keresztül a Webhelyen és/vagy az Alkalmazáson belül. Az utolsó
          frissítés dátumát is frissítjük a ÁSZF dokumentum tetején. A{" "}
          <WineUpInText>WINEUP! </WineUpInText>további felhasználása előtt
          megkövetelheti Öntől, hogy meghatározott módon adjon hozzájárulást a
          frissített ÁSZF-hez. Ha az ilyen változás(ok)ról szóló értesítést
          követően nem fogadja el a változtatás(oka)t, abba kell hagynia a{" "}
          <WineUpInText>WINEUP!</WineUpInText> használatát. 2 Létrehozva: 2023.
          október 25. Frissítve: 2023. november 7. Ellenkező esetben a{" "}
          <WineUpInText>WINEUP! </WineUpInText>
          további használata az ilyen változtatás(ok) elfogadását jelenti.
          Kérjük, a weboldal látogatásakor ellenőrizze az aktuális ÁSZF-et.
        </Text>
        <Text marginBottom={1}>
          5. A <WineUpInText>WINEUP!</WineUpInText> folyamatosan fejlődik, ennek
          eredményeként a <WineUpInText>WINEUP!</WineUpInText> megkövetelheti,
          hogy fogadja el a számítógépére vagy mobileszközére telepített{" "}
          <WineUpInText>WINEUP!</WineUpInText> frissítéseit. Ön tudomásul veszi
          és elfogadja, hogy a <WineUpInText>WINEUP!</WineUpInText> az Ön
          értesítésével vagy anélkül frissítheti a WINEUP! tulajdonságait.
          <BlueItalicText>
            {` Előfordulhat, hogy a WINEUP! használatához időről időre frissítenie
            kell a harmadik féltől származó szoftvereket.`}
          </BlueItalicText>
        </Text>
        <Text fontWeight={700}>II.3. Jogi szabályozás</Text>
        <Text>
          Az ÁSZF-re a magyar jogszabályok az irányadóak, különösen, de nem
          kizárólagosan az alábbi jogszabályok rendelkezéseire kell tekintettel
          lenni:
        </Text>
        <li>
          <Text>1997. évi CLV. törvény a fogyasztóvédelemről,</Text>
        </li>
        <li>
          <Text>
            2001. évi CVIII. törvény az elektronikus kereskedelmi
            szolgáltatások, valamint az információs társadalommal összefüggő
            szolgáltatások egyes kérdéseiről,
          </Text>
        </li>
        <li>
          <Text>
            2013. évi V. törvény a Polgári Törvénykönyvről, 151/2003. (IX.22.)
            kormányrendelet a tartós fogyasztási cikkekre vonatkozó kötelező
            jótállásról,
          </Text>
        </li>
        <li>
          <Text>
            45/2014. (II.26.) kormányrendelet a fogyasztó és a vállalkozás
            közötti szerződések részletes szabályairól,
          </Text>
        </li>
        <li>
          <Text>
            19/2014. (IV.29.) NGM rendelet a fogyasztó és vállalkozás közötti
            szerződés keretében eladott dolgokra vonatkozó szavatossági és
            jótállási igények intézésének eljárási szabályairól,
          </Text>
        </li>
        <li>
          <Text>
            1999. évi LXXVI. törvény a szerzői jogról, 2011. évi CXII. törvény
            az információs önrendelkezési jogról és az információszabadságról,
            AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2018/302 RENDELETE (2018.
            február 28.) a belső piacon belül a vevő állampolgársága, lakóhelye
            vagy letelepedési helye alapján történő indokolatlan területi alapú
            tartalomkorlátozással és a megkülönböztetés egyéb formáival szembeni
            fellépésről, valamint a 2006/2004/EK és az (EU) 2017/2394 rendelet,
            továbbá a 2009/22/EK irányelv módosításáról,
          </Text>
        </li>
        <li style={{ marginBottom: "1rem" }}>
          <Text>
            AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (2016.
            április 27.) a természetes személyeknek a személyes adatok kezelése
            tekintetében történő védelméről és az ilyen adatok szabad
            áramlásáról, valamint a 95/46/EK rendelet hatályon kívül
            helyezéséről (általános adatvédelmi rendelet).
          </Text>
        </li>
        <Text fontWeight={700}>
          II.4. A szerződés nyelve, a szerződés formája
        </Text>
        <Text marginBottom={1}>
          A jelen ÁSZF hatálya alá tartozó szerződések nyelve a magyar nyelv. A
          jelen ÁSZF hatálya alá tartozó szerződések nem minősülnek írásba
          foglalt szerződéseknek, azokat a Szolgáltató nem iktatja.
        </Text>
        <Text fontWeight={700}>II.5. Szerzői jogok</Text>
        <Text marginBottom={1}>
          A szerzői jogról szóló 1999. évi LXXVI. törvény (továbbiakban: Szjt.)
          1. § (1) bekezdése értelmében a weboldal szerzői műnek minősül, így
          annak minden része szerzői jogi védelem alatt áll. Az Szjt. 16. § (1)
          bekezdése alapján tilos a weboldalon található grafikai és szoftveres
          megoldások, számítógépi programalkotások engedély nélküli
          felhasználása, illetve bármely olyan alkalmazás használata, amellyel a
          weboldal, vagy annak bármely része módosítható. A weboldalról és annak
          adatbázisából bármilyen anyagot átvenni a jogtulajdonos írásos
          hozzájárulása esetén is csak a weboldalra való hivatkozással, forrás
          feltüntetésével lehet. A jogtulajdonos: IVE IT Team Kft.
          (Szolgáltató).
        </Text>
        <Text fontWeight={700}>II.6. Kérdések, panaszok</Text>
        <Text marginBottom={1}>
          Ha bármilyen kérdése, panasza vagy követelése van a{" "}
          <WineUpInText>WINEUP!</WineUpInText>-pal kapcsolatban, kérjük, lépjen
          kapcsolatba velünk a{" "}
          <a href="https://mywineup.com" target="_blank" rel="noreferrer">
            https://mywineup.com
          </a>{" "}
          oldalon. Minden tőlünk telhetőt megteszünk aggályai megoldása
          érdekében.
        </Text>
        <Text fontWeight={700}>II.7. Adatvédelem</Text>
        <Text marginBottom={1}>
          A Felhasználói adatok kezelésére és védelmére vonatkozó részletes
          információkat az <Text fontWeight={700}>Adatvédelmi Szabályzat</Text>{" "}
          tartalmazza, amely elérhető a weboldal és az alkalmazás felületén is.
        </Text>
        <Text fontWeight={700}>II.8. Elektronikus kommunikáció</Text>
        <Text marginBottom={1}>
          Az Ön és a <WineUpInText>WINEUP!</WineUpInText> közötti kommunikáció
          elektronikus úton történhet, függetlenül attól, hogy Ön felkeresi a{" "}
          <WineUpInText>WINEUP!</WineUpInText>-ot, vagy e-maileket küld, vagy a
          <WineUpInText> WINEUP!</WineUpInText> értesítéseket tesz közzé, vagy
          e-mailben kommunikál Önnel. A Szolgáltató nem folytat közszolgáltatási
          tevékenységet, így nem vonatkoznak rá az ilyen tevékenységet folytató
          szolgáltatókra vonatkozó speciális törvényi szabályok (speciális
          ügyfélszolgálat működtetése, meghosszabbított ügyfélfogadási idő,
          előzetes időpont foglalás, folyamatos elérhetőség, öt perces
          bejelentkezési idő, stb.).
        </Text>
        <Text fontWeight={700}>II.9. Véglegesítés</Text>
        <Text>
          Az ÁSZF az Ön és a Szolgáltató közötti véglegesített szerződés jellegű
          megállapodás.
        </Text>
        <MainTitle>III. ZÁRÓ RENDELKEZÉSEK</MainTitle>
        <Text>
          1. Jelen ÁSZF-re egyebekben első sorban a Polgári Törvénykönyvről
          szóló 2013. évi V. törvény, a fogyasztó és a vállalkozás közötti
          szerződések részletes szabályairól szóló 45/2014. (II. 26.) Korm.
          rendelet, a fogyasztóvédelemről szóló 1997. évi CLV. törvény, AZ
          EURÓPAI PARLAMENT ÉS A TANÁCS (EU)2016/679 RENDELETE (2016. április
          27.) a természetes személyeknek a személyes adatok kezelése
          tekintetében történő védelméről és az ilyen adatok szabad áramlásáról,
          valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános
          adatvédelmi rendelet) (GDPR), az elektronikus kereskedelmi
          szolgáltatások, valamint az információs társadalommal összefüggő
          szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény,
          illetve a hatályos magyar jogszabályok irányadóak.
        </Text>
      </Container>
    </>
  );
};

export default TermsAndConditions;

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { LanguageContextProvider } from "./LanguageContext";
import Unsubscribe from "./pages/Unsubscribe";
import WineAdventure from "./pages/WineAdventure";
import QandA from "./pages/QandA";
import "./global.css";

const App = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <LanguageContextProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/wine-adventure" element={<WineAdventure />} />
          <Route path="/questions-and-answers" element={<QandA />} />
        </Routes>
      </Router>
    </LanguageContextProvider>
  );
};

export default App;

import React from "react";
import Navbar from "../components/Nav/Navbar";
import {
  Container,
  CreatedAndUpdatedTextContainer,
  Text,
  MainTitle,
  WineUpInText
} from "../components/styled-components";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar isGoBackPage />
      <Container>
        <CreatedAndUpdatedTextContainer>
          <Text marginBottom={1}>Létrehozva: 2023. november 12.</Text>
          <Text>Frissítve: 2023. november 12.</Text>
        </CreatedAndUpdatedTextContainer>
        <MainTitle alignSelf="center">WINEUP!</MainTitle>
        <MainTitle color="#181725" alignSelf="center">
          ADATVÉDELMI TÁJÉKOZTATÓ ÉS ADATKEZELÉSI SZABÁLYZAT
        </MainTitle>
        <Text fontStyle="italic">
          Tájékoztatjuk tisztelt felhasználóinkat és honlapunk látogatóit, hogy
          az általuk tett adatszolgáltatás önkéntes. Személyes adataikat az IVE
          IT TEAM KFT. (székhely: 6724 Szeged, Pulz utca 46/b. /112.,
          cégjegyzékszáma: 06 09 023192) dolgozza fel és kezeli.
        </Text>
        <Text fontStyle="italic" opacity={0.8} marginBottom={1}>
          A <WineUpInText>WINEUP!</WineUpInText> (
          <a href="https://mywineup.com" target="_blank" rel="noreferrer">
            https://mywineup.com
          </a>
          ) weboldal és az ott meghatározott egyéb címeken elérhető weboldalak
          (a továbbiakban: „weboldal”) által nyújtott szolgáltatásaihoz
          kapcsolódóan a felhasználó természetes személyek („ügyfél“) valamennyi
          adatkezelése során jelen Adatvédelmi Tájékoztató és Adatkezelési
          Szabályzat (a továbbiakban: szabályzat) alapján jár el.
        </Text>
        <Text fontWeight={700}>1. Értelmező rendelkezések</Text>
        <Text>
          1.1. Adatkezelő: az a természetes vagy jogi személy, illetve jogi
          személyiséggel nem rendelkező szervezet, aki vagy amely önállóan vagy
          másokkal együtt az adat kezelésének célját meghatározza, az
          adatkezelésre (beleértve a felhasznált eszközt) vonatkozó döntéseket
          meghozza és végrehajtja, vagy az adatfeldolgozóval végrehajtatja.
        </Text>
        <Text>
          1.2. Adatkezelés: az alkalmazott eljárástól függetlenül az adaton
          végzett bármely művelet vagy a műveletek összessége, így különösen
          gyűjtése, felvétele, rögzítése, rendszerezése, tárolása,
          megváltoztatása, felhasználása, lekérdezése, továbbítása,
          nyilvánosságra hozatala, összehangolása vagy összekapcsolása,
          zárolása, törlése és megsemmisítése, valamint az adat további
          felhasználásának megakadályozása, fénykép-, hang- vagy képfelvétel
          készítése, valamint a személy azonosítására alkalmas fizikai jellemzők
          (pl. ujj- vagy tenyérnyomat, DNS-minta, íriszkép) rögzítése.
        </Text>
        <Text>
          1.3. Személyes adat: az érintettel kapcsolatba hozható adat –
          különösen az érintett neve, azonosító jele, valamint egy vagy több
          fizikai, fiziológiai, mentális, gazdasági, kulturális vagy szociális
          azonosságára jellemző ismeret –, valamint az adatból levonható, az
          érintettre vonatkozó következtetés.
        </Text>
        <Text>
          1.4. Hozzájárulás: az érintett akaratának önkéntes és határozott
          kinyilvánítása, amely megfelelő tájékoztatáson alapul, és amellyel
          félreérthetetlen beleegyezését adja a rá vonatkozó személyes adat –
          teljes körű vagy egyes műveletekre kiterjedő – kezeléséhez.
        </Text>
        <Text>
          1.5. Adatfeldolgozás: az adatkezelési műveletekhez kapcsolódó
          technikai feladatok elvégzése, függetlenül a műveletek végrehajtásához
          alkalmazott módszertől és eszköztől, valamint az alkalmazás helyétől,
          feltéve hogy a technikai feladatot az adaton végzik.
        </Text>
        <Text>
          1.6. Adatfeldolgozó: az a természetes vagy jogi személy, illetve jogi
          személyiséggel nem rendelkező szervezet, aki vagy amely szerződés
          alapján – beleértve a jogszabály rendelkezése alapján kötött
          szerződést is – adatok feldolgozását végzi.
        </Text>
        <Text marginBottom={1}>
          1.7. Érintett: bármely meghatározott, személyes adat alapján
          azonosított vagy – közvetlenül vagy közvetve – azonosítható
          természetes személy. Hatóság arról az adatkezelésről, amely az
          adatkezelővel munkaviszonyban, tagsági viszonyban, – a pénzügyi
          szervezetek, közüzemi adatkezelők, elektronikus hírközlési adatkezelők
          ügyfelei kivételével – ügyfélkapcsolatban álló személyek adataira
          vonatkozik.
        </Text>
        <Text fontWeight={700}>
          2. Az adatok megismerésére jogosult természetes és jogi személyek, az
          adatfeldolgozó
        </Text>
        <Text marginBottom={1}>
          Az adatokat az adatkezelő alapító okiratában meghatározott vezetősége,
          illetve a vezetőség által külön erre felhatalmazott személyek
          jogosultak megismerni. Adatkezelő az adatokat nem teszi közzé,
          harmadik személyek számára nem adja ki.
        </Text>
        <Text fontWeight={700}>3. A kezelt személyes adatok köre</Text>
        <Text>
          A jelen szabályzat kizárólag a természetes személyek adatainak a
          kezelésére terjed ki, tekintettel arra, hogy személyes adatok
          kizárólag természetes személyek vonatkozásában értelmezhetők. A
          weboldal bármely internethasználó által regisztráció nélkül
          hozzáférhető tartalmakat is biztosít. Bizonyos tartalmi szolgáltatásai
          és hírlevél funkciója azonban csak regisztrált felhasználók számára
          hozzáférhető. Regisztrált felhasználó az lehet, akit az adatkezelő a
          saját belső szabályzatának megfelelően erre feljogosít. A regisztráció
          folyamata ingyenes.
        </Text>
        <Text>
          3.1. A regisztráció során az ügyfélnek kötelezően meg kell adnia a
          következő személyes adatokat: ügyfél neve, e-mail címe, ügyfél által
          kitalált jelszó.
        </Text>
        <Text>
          3.2. A{" "}
          <a href="https://mywineup.com" target="_blank" rel="noreferrer">
            https://mywineup.com
          </a>{" "}
          adatbázisa az ügyfél adatait a regisztrációval nyújtott ingyenes
          szolgáltatások teljesítése érdekében tárolja, azokat az adatkezelő
          továbbadni sem reklámfelhasználás, sem egyéb célra – az ügyfél
          kifejezett hozzájárulása hiányában – nem jogosult.
        </Text>
        <Text>
          3.3. A weboldalon tett látogatások során egy vagy több cookie-t – apró
          információ csomagot, amelyet a szerver küld a böngészőnek, majd a
          böngésző vissza küld a szervernek minden, a szerver felé irányított
          kérés alkalmával – küldünk az ügyfél számítógépére, amely(ek) révén
          annak böngészője egyedileg azonosítható lesz. Ezen cookie-k kizárólag
          a felhasználói élmény javítása, a belépési folyamat automatizálása,
          valamint reklám tevékenységünk hatékonyságának mérése érdekében
          működnek.
        </Text>
        <Text>
          3.4. Az ügyfél személyes adatainak kezelése során az adatkezelő
          betartja az információs önrendelkezési jogról szóló 2011. évi CXII.
          törvény és az Európai Unió általános adatvédelmi rendelete (a
          továbbiakban: GDPR) előírásait.
        </Text>
        <Text marginBottom={1}>
          3.5. Az ügyfél mindenkor jogosult az adatkezelést letiltani.
        </Text>
        <Text fontWeight={700}>
          4. Az adatkezelés jogalapja, módja és célja
        </Text>
        <Text>
          4.1. Az adatkezelés jogalapja az ügyfél önkéntes hozzájárulása. A
          hozzájárulást az ügyfél a fentiekben megjelölt adatok kezelése
          tekintetében jelen szabályzat előzetes megismerését követően történő
          kifejezett elfogadásával – az erre vonatkozó jelölőnégyzet
          kipipálásával – illetőleg a weboldal használatával, a regisztrációval,
          a kérdéses adatok önkéntes megadásával adja meg. A hírlevéllel
          kapcsolatban az ügyfél az erre vonatkozó jelölőnégyzet kipipálásával
          kifejezett és önkéntes hozzájárulását adja ahhoz, hogy az adatkezelő a
          weboldallal kapcsolatos híreket, információkat küldjön az ügyfél által
          a regisztráció során megadott e-mail címre.
        </Text>
        <Text>
          4.2. Az ügyfél a weboldalra történő belépéssel, és a weboldal
          használatával magára nézve kötelezőnek fogadja el a jelen szabályzat
          rendelkezéseit. A szabályzat célja, hogy meghatározza az adatkezelő
          által kezelt személyes adatok körét, az adatkezelés módját, valamint
          biztosítsa az adatvédelem és adatkezelés alkotmányos elveinek, az
          adatbiztonság követelményeinek érvényesülését annak érdekében, hogy a
          felhasználó természetes személyek magánszférájának a tiszteletben
          tartása megvalósuljon az érintettek személyes adatainak gépi
          feldolgozása, illetőleg kezelése során
        </Text>
        <Text>
          4.3. A regisztráció során megadott elektronikus levélcímek (e-mail
          címek) adatkezelő általi felhasználásának módja a következő: az e-mail
          címek kezelése az ügyfél azonosítására, a szolgáltatások igénybevétele
          során a kapcsolattartásra, illetőleg az ügyfél részére hírlevél
          megküldésére szolgál. Adatkezelő az általa nyújtott szolgáltatásokra
          vonatkozó tájékoztatást elektronikus formában juttatja el ügyfél
          részére. Adatkezelő az ügyfél kifejezett hozzájárulásával küld a
          regisztráció során megadott e-mail címre a weboldal tartalmaival
          kapcsolatos információkat tartalmazó hírleveleket. Adatkezelő
          kizárólag azon ügyfelei számára küld hírleveleket, akik az erre
          szolgáló menüpontban ehhez kifejezett hozzájárulásukat adták.
        </Text>
        <Text>
          4.4. Az ügyfél a hírlevél szolgáltatásról bármikor, ingyenesen és
          indoklás nélkül leiratkozhat. A leiratkozás történhet egyetlen
          lépésben, a hírlevélben található linkre kattintással, illetve
          leiratkozhat az adatkezelőnek küldött e-mail formájában is. Ebben az
          esetben adatkezelő haladéktalanul törli a nyilvántartásából az ügyfél
          adatait.
        </Text>
        <Text>
          4.5. Az ügyfél által megadott telefonszám adatkezelő általi
          felhasználási célja a kapcsolattartás.
        </Text>
        <Text>
          4.6. Az ügyfél adatait kizárólag számítástechnikai eszközzel
          végrehajtott adatfeldolgozással kezeljük. Az automatikusan rögzítésre
          kerülő adatok célja statisztikakészítés, az informatikai rendszer
          technikai fejlesztése és a felhasználók jogainak védelme. Az
          automatikusan rögzítésre kerülő adatok az alábbiak: az ügyfél
          számítógépének dinamikus IP címe, az ügyfél számítógépének
          beállításaitól függően az ügyfél által használt számítógép operációs
          rendszerének és böngészőjének típusa, az ügyfélnek a weboldallal
          kapcsolatos aktivitása. Ezen adatok felhasználása egyrészről technikai
          célokat szolgál - pl. a szerverek biztonságos üzemeltetése, utólagos
          ellenőrzése, másrészt az adatkezelő ezen adatokat oldalhasználati
          statisztikák készítéséhez, a felhasználói igények elemzéséhez
          használja fel a szolgáltatások színvonalának emelése érdekében. A
          fenti adatok az ügyfél azonosítására nem alkalmasak, és ezeket az
          adatkezelő egyéb személyes adatokkal nem kapcsolja össze.
        </Text>
        <Text>
          4.7. Az adatkezelő a megadott személyes adatokat az e pontokban írt
          céloktól eltérő célokra nem használhatja fel. Személyes adatok
          harmadik személynek vagy hatóságok számára történő kiadása - hacsak
          törvény ettől eltérően nem rendelkezik kötelező erővel - a felhasználó
          előzetes, kifejezett hozzájárulása esetén lehetséges kizárólag.
        </Text>
        <Text marginBottom={1}>
          4.8. Adatkezelő a neki megadott személyes adatokat nem ellenőrzi. A
          megadott adatok megfelelőségéért kizárólag az azt megadó személy
          felel. Bármely ügyfél e-mail címének megadásakor egyben felelősséget
          vállal azért, hogy a megadott e-mail címről kizárólag ő vesz igénybe
          szolgáltatást. E felelősségvállalásra tekintettel egy megadott e-mail
          címen történt belépésekkel összefüggő mindennemű felelősség kizárólag
          azt a felhasználót terheli, aki az e-mail címet regisztrálta.
        </Text>
        <Text fontWeight={700}>5. Az adatkezelés időtartama</Text>
        <Text>
          5.1. A regisztráció során kötelezően megadott adatok kezelése a
          regisztrációval kezdődik és annak törléséig tart. Nem kötelező adatok
          esetén az adatkezelés az adat megadásának időpontjától a kérdéses adat
          törléséig tart. A regisztráció során rögzített adatok
          megváltoztatását, vagy törlését a regisztrált ügyfél a bejelentkezést
          követően a „Saját fiókban” kezdeményezheti.
        </Text>
        <Text marginBottom={1}>
          5.2. Fenti rendelkezések nem érintik a jogszabályban (pl. számviteli
          jogszabályokban) meghatározott megőrzési kötelezettségek teljesítését.
        </Text>
        <Text fontWeight={700}>
          6. Az adatokat megismerni jogosult személyek köre
        </Text>
        <Text>
          6.1. Az adatokat elsődlegesen adatkezelő, illetve adatkezelő belső
          munkatársai jogosultak megismerni, azonban azokat nem teszik közzé,
          harmadik személyek részére nem adják át.
        </Text>
        <Text>
          6.2. Adott esetben a weboldal és az informatikai rendszer
          üzemeltetése, az elszámolás rendezése körében adatkezelő
          adatfeldolgozót (pl. rendszerüzemeltető, könyvelő) vehet igénybe.
          Adatkezelő az ilyen külső szereplők adatkezelési gyakorlatáért nem
          felelős.
        </Text>
        <Text marginBottom={1}>
          6.3. A fentieken túl az ügyfélre vonatkozó személyes adatok
          továbbítására kizárólag törvényben kötelezően meghatározott esetben,
          illetve az ügyfél hozzájárulása alapján kerülhet sor.
        </Text>
        <Text fontWeight={700}>7. Az ügyfél jogai</Text>
        <Text>
          7.1. Az ügyfél bármikor jogosult tájékoztatást kérni az adatkezelő
          által kezelt, rá vonatkozó személyes adatokról, továbbá bármikor
          módosíthatja azokat. Ügyfél jogosult továbbá adatai törlésének
          kérésére az e pontban megadott elérhetőségek útján.
        </Text>
        <Text>
          7.2. Adatkezelő az ügyfél kérésére tájékoztatást ad a rá vonatkozó,
          általa kezelt adatokról, az adatkezelés céljáról, jogalapjáról,
          időtartamáról, továbbá arról, hogy kik és milyen célból kapják vagy
          kapták meg adatait. Adatkezelő a kérelem benyújtásától számított 30
          napon belül írásban adja meg a kért tájékoztatást.
        </Text>
        <Text>
          7.3. Az ügyfél bármely, az adatkezeléssel kapcsolatos kérdéssel,
          illetve észrevétellel az adatkezelő munkatársához fordulhat a fenti
          elérhetőségeken keresztül. Levelezési cím: Magyarország 6724 Szeged,
          Pulz utca 46/b. /112.
        </Text>
        <Text>
          7.4. Az ügyfél bármikor jogosult a helytelenül rögzített adatainak
          helyesbítését, vagy azok törlését kérni. Egyes adatait az ügyfél a
          weboldalon maga javíthatja; egyéb esetekben adatkezelő a kérelem
          beérkezésétől számított 3 munkanapon belül törli az adatokat, ez
          esetben azok nem lesznek újra helyreállíthatók. A törlés nem
          vonatkozik a jogszabály (pl. számviteli szabályozás) alapján szükséges
          adatkezelésekre, azokat adatkezelő a szükséges időtartamig megőrzi.
        </Text>
        <Text>
          7.5. Az ügyfél bíróság előtt érvényesítheti jogait, kérheti továbbá az
          adatvédelmi biztos segítségét is. Ügyfél, jogsértés esetén
          jogorvoslatért fordulhat: az Adatvédelmi Biztos Hivatalához és a
          Nemzeti Adatvédelmi és Információszabadság Hatósághoz fordulhat.
        </Text>
        <Text marginBottom={1}>
          7.6. Amennyiben az ügyfél szolgáltatás igénybevételéhez a regisztráció
          során harmadik fél adatait adta meg, vagy a weboldal használata során
          bármilyen módon kárt okozott, az adatkezelő jogosult az ügyféllel
          szembeni kártérítés érvényesítésére. Az adatkezelő ilyen esetben
          minden tőle telhető segítséget megad az eljáró hatóságoknak a jogsértő
          személy személyazonosságának megállapítása céljából.
        </Text>
        <Text fontWeight={700}>8. Egyéb rendelkezések</Text>
        <Text>
          8.1. Adatkezelő rendszere a felhasználók aktivitásáról adatokat
          gyűjthet, melyek nem kapcsolhatóak össze a felhasználók által a
          regisztrációkor megadott egyéb adatokkal, sem más honlapok vagy
          szolgáltatások igénybevételekor keletkező adatokkal.
        </Text>
        <Text>
          8.2. Minden olyan esetben, ha a megadott adatokat az adatkezelő az
          eredeti adatfelvétel céljától eltérő célra kívánja felhasználni, erről
          a felhasználót tájékoztatja, és ehhez előzetes, kifejezett
          hozzájárulását megszerzi, illetőleg lehetőséget biztosít számára, hogy
          a felhasználást megtiltsa.
        </Text>
        <Text>
          8.3. Adatkezelő kötelezi magát, hogy gondoskodik az adatok
          biztonságáról, megteszi továbbá azokat a technikai intézkedéseket,
          amelyek biztosítják, hogy a felvett, tárolt, illetve kezelt adatok
          védettek legyenek, illetőleg mindent megtesz annak érdekében, hogy
          megakadályozza azok megsemmisülését, jogosulatlan felhasználását és
          jogosulatlan megváltoztatását. Kötelezi magát arra is, hogy minden
          olyan harmadik felet, akiknek az adatokat esetlegesen továbbítja vagy
          átadja, ugyancsak felhívja ez irányú kötelezettségeinek teljesítésére.
        </Text>
        <Text>
          {`8.4. Adatkezelő fenntartja a jogot, hogy jelen szabályzatot az
          ügyfelek előzetes értesítése mellett egyoldalúan módosítsa. A
          módosítás hatályba lépését követően az ügyfél a szolgáltatás
          használatával ráutaló magatartással elfogadja a módosított
          szabályzatban foglaltakat. Az adatkezelő magára nézve kötelezőnek
          ismeri el jelen szabályzat tartalmát, és kötelezettséget vállal arra,
          hogy szolgáltatásával kapcsolatos adatkezelése megfelel a jelen
          szabályzatban megfogalmazott előírásoknak. `}
        </Text>
      </Container>
    </>
  );
};

export default PrivacyPolicy;

import styled from "styled-components";
import { ReactComponent as CaretUp } from "../assets/icons/CaretUp.svg";
import { ReactComponent as CaretDown } from "../assets/icons/CaretDown.svg";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  padding: 1.5rem;
  border-top: 1px solid #dadada;
  margin-bottom: 1rem;
  flex-direction: column;
  ${({ last }) =>
    last &&
    `
    border-bottom: 1px solid #DADADA;
    margin-bottom: 0;
  `}
`;

const Title = styled.span`
  font-family: Playfair Display, serif;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  color: #181725;
  font-weight: bold;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Description = styled.span`
  margin-top: 2rem;
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  color: #181725;
`;

const QuestionLine = ({ title, description, last }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Container last={last}>
      <TitleContainer>
        <Title>{title}</Title>
        {expanded ? (
          <CaretUp onClick={() => setExpanded(false)} style={{minWidth: '24px', minHeight: '24px', cursor: 'pointer'}}/>
        ) : (
          <CaretDown onClick={() => setExpanded(true)} style={{minWidth: '24px', minHeight: '24px', cursor: 'pointer'}}/>
        )}
      </TitleContainer>
      {expanded ? (
        <Description>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </Description>
      ) : null}
    </Container>
  );
};

export default QuestionLine;

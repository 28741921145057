import styled from "styled-components";
import Navbar from "../components/Nav/Navbar";
import qandAHero from "../assets/qanda-hero.png";
import DownloadApp from "../components/DownloadApp";
import Footer from "../components/Footer";
import Questions from "../components/Questions";

const QandAContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 8rem;
  margin-bottom: 8rem;
  align-items: center;

  @media (max-width: 640px) {
    margin-bottom: 4rem;
    margin-top: 4rem;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  @media (min-width: 641px) and (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 6rem;
    margin-top: 6rem;
  }
`;

const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 1rem 1rem;
  box-sizing: border-box;

  @media (max-width: 640px) {
    margin: 2rem 1rem;
    padding: 0 0.5rem 1.5rem;
    transition: width 0.5s ease;
  }
`;

const BigContainer = styled.div`
  max-width: 1440px;
  margin: 4rem auto;
  padding: 0 1rem 1.5rem;
  box-sizing: border-box;

  @media (max-width: 640px) {
    margin: 2rem 1rem;
    padding: 0 0.5rem 1.5rem;
    transition: width 0.5s ease;
  }
`;

const QAHero = styled.img`
  width: 100%;
`;

const QandA = () => {

  return (
    <>
      <Container>
        <Navbar />
      </Container>
      <QAHero src={qandAHero} alt="q-and-a-hero" />
      <BigContainer>
        <QandAContainer>
          <Questions />
        </QandAContainer>
        <DownloadApp />
      </BigContainer>
      <Footer />
    </>
  );
};

export default QandA;

import * as React from "react";
import styled from "styled-components";
import Hero from "../components/Hero";
import HowItWorks from "../components/HowItWorks";
import NewOpportunities from "../components/NewOpportunities";
import FeaturesforWineries from "../components/FeaturesforWineries";
import FeaturesforWineConsumers from "../components/FeaturesforWineConsumers";
import Partners from "../components/Partners";
import DownloadApp from "../components/DownloadApp";
import Footer from "../components/Footer";
import Navbar from "../components/Nav/Navbar";
import Events from "../components/Events";
import Questions from "../components/Questions";

const Container = styled.div`
max-width: 1440px;
margin:  0 auto;
padding: 1rem 1rem;
box-sizing: border-box;

@media (max-width: 640px) {
  margin: 2rem 1rem;
  padding: 0 0.5rem 1.5rem;
  transition: width 0.5s ease;
}
`;

const ContainerFluid = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;

const Home = () => {
  return (
    <ContainerFluid>
      <Container>
        <Navbar />
        <Hero />
        <HowItWorks />
      </Container>
      <NewOpportunities />
      <Container>
        <FeaturesforWineries />
        <FeaturesforWineConsumers />
      </Container>
      <Events />
      <Container>
        <Partners />
        <Questions isHome/>
        <DownloadApp />
      </Container>
      <Footer />
    </ContainerFluid>
  );
};

export default Home;

import React from "react";
import styled from "styled-components";
import googleDownload from "../assets/google-download.png";
import appleDownload from "../assets/apple-download.png";
import hero from "../assets/hero_iPhone_ 15_Pro.png";
import { APPSTORE_LINK, GOOGLEPLAY_LINK } from "../utils/consts";
import { useMyLanguageContext } from "../LanguageContext";

const Container = styled.div`
  display: flex;
  margin-bottom: 8rem;
  padding-top: 4rem;

  @media (max-width: 575.98px) {
    margin-bottom: 4rem;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 767.98px) {
    align-items: center;
    text-align: center;
    width: 100%;
  }
`;

const Title = styled.span`
  font-family: Playfair Display, serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 108.1%;
  letter-spacing: 0.1125rem;
  color: ${({ color }) => color ?? "#181725"};
  ${({ marginbottom = 0 }) => `margin-bottom: ${marginbottom}rem;`}
`;

const Text = styled.span`
  font-family: Nunito, sans-serif;
  font-size: 1.5rem;
  color: #181725;
  ${({ fontWeight = 400 }) => `font-weight: ${fontWeight};`}
  ${({ fontStyle = "medium" }) => `font-style: ${fontStyle};`}
  ${({ marginbottom = 0 }) => `margin-bottom: ${marginbottom}rem;`}
  ${({ opacity = 1 }) => `opacity: ${opacity};`}
`;

const DownloadButtonImage = styled.img`
  ${({ marginright = 0 }) => `margin-right: ${marginright}px;`}
  cursor: pointer;

  @media (max-width: 375px) {
    margin-right: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;

  @media (max-width: 375px) {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const HeroImage = styled.img`
flex: 1;
width: 100%;
height: auto;
transition: ease all 0.5s;
max-width: 100%; 


@media (max-width: 575.98px) {
  min-width: auto;
  width: 100%;
  padding-top: 2rem;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  min-width: auto;
  width: 75%;
  padding-top: 2rem;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  min-width: auto;
  width: 65%;
  padding-top: 2rem;
}

@media (min-width: 992px) {
  min-width: 850px;
  max-width: 100%;
}
`;

const Hero = () => {
  const { i18n } = useMyLanguageContext();

  return (
    <Container>
      <ContentContainer>
        <Title marginbottom={3}>
          {i18n.t("heroSection.firstHeroParagraph")}
        </Title>
        <Text marginbottom={2}>
          {i18n.t("heroSection.secondHeroParagraph")}
        </Text>
        <Text marginbottom={2} >
          {i18n.t("heroSection.thirdHeroParagraph")}
        </Text>
        <ButtonContainer>
          <a href={GOOGLEPLAY_LINK} target="_blank" rel="noreferrer">
            <DownloadButtonImage
              src={googleDownload}
              alt="Get it on Google Play"
              marginright={8}
            />
          </a>
          <a href={APPSTORE_LINK} target="_blank" rel="noreferrer">
            <DownloadButtonImage
              src={appleDownload}
              alt="Download on the App Store"
            />
          </a>
        </ButtonContainer>
      </ContentContainer>
      <HeroImage src={hero} alt="hero" />
    </Container>
  );
};

export default Hero;
